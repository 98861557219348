import React from 'react';
import profile from './assets/images/profile-picture.jpg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope  } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt, faLink, faPaperPlane, faGraduationCap, faStar, faStarHalf  } from '@fortawesome/free-solid-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="container clearfix">
          <img class="profile-image img-fluid float-left" src={profile} alt="Brenno Candido" />
          <div class="profile-content float-left">
            <h1 class="name">Brenno Candido</h1>
            <h2 class="desc">Software Developer Engineer</h2>
            <ul class="social list-inline">
              <li class="list-inline-item">
                <FontAwesomeIcon icon={['fab', 'apple']} />
                <a style={{ display: "table-cell" }} href="https://www.linkedin.com/in/bcandido/">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </li>
              <li class="list-inline-item last-item">
                <a style={{ display: "table-cell" }} href="https://github.com/bcandido">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </li>
            </ul>
          </div>
          <a class="btn btn-cta-primary float-right" href="mailto:bcandido.bc@gmail.com">
            <FontAwesomeIcon icon={faPaperPlane} />  Contact Me
          </a>
        </div>
      </header>
      
      <div class="container sections-wrapper">
        <div class="row">
          <div class="primary col-lg-8 col-12">

          <section class="education section">
              <div class="section-inner">
                <h2 class="heading">Education</h2>
                <div class="content">
                  <div class="item">
                      <h3 class="title"><FontAwesomeIcon icon={faGraduationCap} /> Specialization in Software Engineering</h3>
                      <h4 class="university">Campinas State University <span class="year">(February/2019 - December/2019)</span></h4>
                  </div>
                  <div class="item">
                      <h3 class="title"><FontAwesomeIcon icon={faGraduationCap} /> BSc Computer Science</h3>
                      <h4 class="university">University of São Paulo <span class="year">(March/2011 - July/2016)</span></h4>
                  </div>
                  <div class="item">
                      <h3 class="title"><FontAwesomeIcon icon={faGraduationCap} /> BSc Computer Security</h3>
                      <h4 class="university">University of South Wales <span class="year">(September/2014 - July/2015)</span></h4>
                  </div>
                  <div class="item">
                      <h3 class="title"><FontAwesomeIcon icon={faGraduationCap} /> English Programme</h3>
                      <h4 class="university">University of South Wales <span class="year">(May/2014 - August/2014)</span></h4>
                  </div>
                </div>
              </div>
            </section>
            
            {/* <section class="about section">
              <div class="section-inner">
                <h2 class="heading">About Me</h2>
                <div class="content">
                  <p>Write a brief intro about yourself</p>
                </div>
              </div>
            </section> */}

            {/* <section class="skill section">
              <div class="section-inner">
                <h2 class="heading">Skills</h2>
                <div class="content">
                  <h3 class="title">Programming</h3>
                  Most used:<br/>
                  Java <span>&#8226;</span> Kotlin <span>&#8226;</span> Shell/Bash <span>&#8226;</span> Python
                  <br/>
                  Others: <br/>
                  C/C++ <span>&#8226;</span> Golang <span>&#8226;</span> Groovy
                  <br/>
                  <h3 class="title">Management Processes</h3>
                  Scrum <span>&#8226;</span> Kanban
                  <br/>
                  <h3 class="title">Technologies</h3>
                  <table>
                    <tr>
                      <td>SpringBoot</td>
                      <td>Vert.x</td>
                      <td>Unit Testing</td>
                    </tr>
                    <tr>
                      <td>SQL</td>
                      <td>PostgreSQL</td>
                      <td>Redis</td>
                    </tr>
                    <tr>
                      <td>Docker</td>
                      <td>Kubernetes</td>
                      <td>Jenkins</td>
                    </tr>
                    <tr>
                      <td>Terraform</td>
                      <td>Chef</td>
                      <td>SaltStack</td>
                    </tr>
                  </table>
                </div>
              </div>
            </section> */}

            <section class="experience section">
              <div class="section-inner">
                <h2 class="heading">Work Experience</h2>
                <div class="content">
                  <div class="item">
                    <h3 class="title">Software Developer Engineer - <span class="place"><a href="#">IFood</a></span> <span class="year">(September/2018 - Present)</span></h3>
                    <ul>
                      <li>Develop financial application using Java / Kotlin</li>
                      <li>Perform data analysis using PySpark and PostgreSQL</li>
                      <li>Maintenance and evolution of new business functions</li>
                    </ul>
                  </div>
                  <div class="item">
                    <h3 class="title">DevOps Engineer - <span class="place"><a href="#">Daitan Group</a></span> <span class="year">(July/2016 - August/2018)</span></h3>
                    <ul>
                      <li>Provision Application for AWS/GCE developed in Python;</li>
                      <li>Deployment Applications using SaltStack Configuration Manager;</li>
                      <li>Continuous Integration processes using Jenkins/GitHub;</li>
                      <li>Continuous Integration Application developed in Groovy;</li>
                      <li>Kubernetes GKE Cluster Maintenance.</li>
                    </ul>
                  </div>

                  <div class="item">
                    <h3 class="title">DevOps Engineer Intern - <span class="place"><a href="#">Daitan Group</a></span> <span class="year">(July/2015 - July/2016)</span></h3>
                    <ul>
                      <li>Continuous Deployment processes using Atlassian Bamboo;</li>
                      <li>Bash and Ruby Scripts for environment configuration and deploy automation;</li>
                      <li>Provision Environments using VMWare vSphere;</li>
                      <li>Deployment Applications using Chef Configuration Manager.</li>
                    </ul>
                  </div>

                </div>
              </div>
            </section>

            <section class="experience section">
              <div class="section-inner">
                <h2 class="heading">Research</h2>
                <div class="content">
                  <div class="item">
                    <h3 class="title">Undergraduate Researcher Fellow <br/><span class="place"><a href="#">Information Security Research Lab</a></span> <span class="year">(June/2015 - August/2015)</span></h3>
                    <p>I worked with Prof. Konstantinos Xynos to develop an expandable and easily adaptable manage console tool to automate time-consuming task/checks during the manual process of Penetration Test.</p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="secondary col-lg-4 col-12">
            <aside class="info aside section">
              <div class="section-inner">
                <h2 class="heading sr-only">Basic Information</h2>
                <div class="content">
                  <ul class="list-unstyled">
                    <li><FontAwesomeIcon icon={faMapMarkerAlt} /><span class="sr-only">Location:</span> Campinas, Brazil</li>
                    <li><FontAwesomeIcon icon={faEnvelope} /><span class="sr-only">Email:</span><a href="#">bcandido.bc@gmail.com</a></li>
                    <li><FontAwesomeIcon icon={faLink} /><span class="sr-only">Website:</span><a href="#">https://www.bcandido.com</a></li>
                  </ul>
                </div>
              </div>
            </aside>

            <aside class="languages aside section">
              <div class="section-inner">
                <h2 class="heading">Languages</h2>
                <div class="content">
                  <ul class="list-unstyled">
                    <li class="item">
                      <span class="title"><strong>Portuguese:</strong></span>
                      <span class="level"> Native Speaker <br class="visible-xs"/>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                    </li>
                    <li class="item">
                      <span class="title"><strong>English:</strong></span>
                      <span class="level"> Professional Proficiency <br class="visible-sm visible-xs"/>
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStarHalf} />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>

            <aside class="aside section">
              <div class="section-inner">
                <h2 class="heading">Characteristics</h2>
                <div class="content">
                  <ul class="list-unstyled">
                    <li>Proactive</li>
                    <li>Hard working</li>
                    <li>Communicative</li>
                    <li>Teamwork</li>
                    <li>Flexible</li>
                  </ul>
                </div>
              </div>
            </aside>

            <aside class="aside section">
              <div class="section-inner">
                <h2 class="heading">Interests</h2>
                <div class="content">
                  <ul class="list-unstyled">
                    <li>Cloud Computing</li>
                    <li>DevOps Culture</li>
                    <li>Software Architecture</li>
                    <li>Distributed Systems</li>
                    <li>Fault Tolerance Systems</li>
                  </ul>
                </div>
              </div>
            </aside>

          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
